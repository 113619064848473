<template>
  <div>
    <v-data-table
      :headers="participantHeaders"
      :items="participantObjects"
      :options.sync="options"
      :loading="!participantsLoaded"
      :hide-default-footer="hideTableFooter"
      :loading-text="$t('Loading...')"
      class="elevation-1"
      item-key="@id"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ $t("Participants") }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            @click="refreshParticipantData()"
            :title='$t("refreshData")'
            fab
            icon
            elevation="2"
            small
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.name="{ item }">
        <template v-if="item.customer">
          {{ getProfileItemBasics(getCustomerItem(item.customer, "profile"), "firstName") }}
          {{ getProfileItemBasics(getCustomerItem(item.customer, "profile"), "lastName") }}
        </template>
        <template v-else>
          {{ item.name }}
          <template v-if="isInhouse">{{ item.email }}</template>
        </template>
      </template>
      <template v-slot:item.customerNumber="{ item }">
        <v-btn
          plain
          color="primary"
          @click="openCustomerDialog(resolveCustomer(item.customer))"
          style="padding: 0"
        >
          {{ getCustomerItem(item.customer, "customerNumber") }}
        </v-btn>
      </template>
      <template v-slot:item.invoiceState="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              class="mr-2"
            >
              <template v-if="item.firstInvoice">
                <template v-if="getInvoiceItem(item.firstInvoice, 'paid')">mdi-receipt-text-check-outline</template>
                <template v-else>mdi-email-check-outline</template>
              </template>
              <template v-else>mdi-email-remove-outline</template>
            </v-icon>
          </template>
          <span>
            <template v-if="item.firstInvoice">
              <template v-if="getInvoiceItem(item.firstInvoice, 'paid')">Bezahlt</template>
              <template v-else>Erstellt & versendet</template>
              ({{ getInvoiceItem(item.firstInvoice, 'number') }})
            </template>
            <template v-else>Nicht erstellt & versendet</template>
          </span>
        </v-tooltip>
      </template>
      <template v-slot:item.registrationDate="{ item }">
        {{ formatDateTime(item.createdAt, "L") }}
      </template>
      <template v-slot:no-data>
        {{ $t("NoParticipantsYet") }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
  import ParticipantMixin from "@/mixins/ParticipantMixin";
  import { mapActions } from "vuex";
  import ProfileMixin from "@/mixins/ProfileMixin";
  import CustomerMixin from "@/mixins/CustomerMixin";
  import DocumentMixin from "../../mixins/DocumentMixin";
  import NotificationMixin from "@/mixins/NotificationMixin";
  import TenantMixin from "@/mixins/TenantMixin";
  import InvoiceMixin from '@/mixins/InvoiceMixin';
  import { formatDateTime } from '@/utils/dates';

  export default {
    name: "ParticipantListPackage",
    components: {
    },
    mixins: [ParticipantMixin, ProfileMixin, CustomerMixin, DocumentMixin, NotificationMixin, TenantMixin, InvoiceMixin],
    props: {
      values: {
        type: Object,
        required: false,
      },
      participants: {
        type: Array,
        default: () => [],
      },
      showTitle: {
        type: Boolean,
        required: false,
        default: () => true,
      },
      isPublicBookable: {
        type: Boolean,
        required: true,
      },
      locked: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        options: {
          itemsPerPage: -1
        },
        forceLoading: false,
      };
    },
    computed: {
      participantHeaders() {
        let headerSet = [
          {
            text: this.$i18n.t("name"),
            value: "name",
            align: 'start'
          },
        ];

        if (!this.isInhouse) {
          headerSet.unshift({
            text: this.$i18n.t("customerNumber"),
            value: "customerNumber",
            sortable: false,
            align: 'start',
            width: '140px'
          });
        }

        if (this.isPublicBookable) {
          headerSet.push({
            text: this.$i18n.t("invoice"),
            value: "invoiceState",
            align: "center",
            width: '90px',
            sortable: false,
          })
          headerSet.push({
            text: this.$i18n.t("registrationDate"),
            value: "registrationDate",
            sortable: false,
            align: 'end',
            width: '140px'
          })
        }
        return headerSet;
      },
      participantObjects() {
        return this.resolveParticipantItems(this.participants);
      },
      participantsLoaded() {
        return !this.forceLoading && this.participants.length === this.participantObjects.length;
      },
      hideTableFooter() {
        return !(this.participantObjects.length > 10);
      },
    },
    methods: {
      ...mapActions("participant", {
        participantAction: "action",
      }),
      formatDateTime,
      async refreshParticipantData() {
        // participantsLoaded
        this.forceLoading = true;
        await this.reloadParticipantsTrackable(this.participants);
        this.forceLoading = false;
      }
    }
  };
</script>
